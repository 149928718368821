import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import * as ROUTES from "../constants/routes";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  responsive: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "96%",
    },
    [theme.breakpoints.up("md")]: {
      width: "90%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "80%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "60%",
    },
  },
  footer: {
    marginLeft: "auto",
    marginRight: "auto",
    color: "#000",
    padding: "30px 0px",
    display: "flex",
    justifyContent: "space-between",
  },
  dividerColor: {
    backgroundColor: "white",
    marginTop: "6vw",
    marginBottom: "5vw",
  },
}));

export default function FooterMobile(props) {
  const classes = useStyles();

  return (
    <div>
      <div
        style={{
          backgroundColor: "#8e4232",
          fontSize: "1rem",
          padding: "5vw 5vw 10vw 5vw",
        }}
      >
        <div className={classes.footers}>
          <div style={{ width: "100%" }}>
            <div style={{ color: "#ffffff" }}>
              <div style={{ fontWeight: "bold", fontSize: "1.3em" }}>
                {" "}
                Anschrift{" "}
              </div>
              <br />
              <span style={{ fontWeight: "bold" }}> Zahnarztpraxis Link </span>
              <br />
              Ullastr. 3
              <br />
              63829 Krombach
              <br />
              <br />
              <span style={{ fontWeight: "bold" }}> Tel.: </span>{" "}
              <a
                href="tel:060242220"
                style={{ color: "#ffffff", textDecoration: "none" }}
              >
                06024 / 2220{" "}
              </a>
              <br />
              <span style={{ fontWeight: "bold" }}>E-Mail: </span>{" "}
              <a
                href="mailto:info@zahnarztpraxis-link.de"
                style={{ color: "#ffffff", textDecoration: "none" }}
              >
                {" "}
                info@zahnarztpraxis-link.de{" "}
              </a>
            </div>
            <Divider className={classes.dividerColor} />

            <div style={{ color: "#ffffff" }}>
              <div style={{ fontWeight: "bold", fontSize: "1.3em" }}>
                {" "}
                Öffnungszeiten{" "}
              </div>
              <br />
              Montag - Freitag
              <br />
              8.00 Uhr - 12.00 Uhr
              <br />
              14.00 Uhr - 18.00 Uhr
              <br />
            
            </div>

            <div style={{ color: "#ffffff" }}>
              <Divider className={classes.dividerColor} />
              <div style={{ fontWeight: "bold", fontSize: "1.3em" }}>
                {" "}
                Notdienst{" "}
              </div>
              <br />
              An Wochenenden, Sonn- und Feiertagen wenden Sie sich bitte an den
              zahnärztlichen Notdienst unter
              <span style={{ fontWeight: "bold" }}>
                {" "}
                Tel.:{" "}
                <a
                  href="tel:0602180700"
                  style={{ color: "#ffffff", textDecoration: "none" }}
                >
                  {" "}
                  06021 / 80 700{" "}
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          backgroundColor: "#612d22",
          paddingTop: 30,
          paddingBottom: 30,
        }}
      >
        <ListItem
          button
          style={{ paddingLeft: "5vw" }}
          onClick={() => props.history.push(ROUTES.DATENSCHUTZ)}
        >
          <ListItemText primary="Datenschutz" style={{ color: "#ffffff" }} />
        </ListItem>
        <ListItem
          button
          style={{ paddingLeft: "5vw" }}
          onClick={() => props.history.push(ROUTES.IMPRESSUM)}
        >
          <ListItemText primary="Impressum" style={{ color: "#ffffff" }} />
        </ListItem>
        <ListItem
          button
          style={{ paddingLeft: "5vw" }}
          onClick={() => props.history.push(ROUTES.BEWERBUNGEN)}
        >
          <ListItemText primary="Bewerbungen" style={{ color: "#ffffff" }} />
        </ListItem>
        <ListItem
          button
          style={{ paddingLeft: "5vw" }}
          onClick={() => props.history.push(ROUTES.KONTAKT)}
        >
          <ListItemText primary="Kontakt" style={{ color: "#ffffff" }} />
        </ListItem>
      </div>
    </div>
  );
}
